import React, { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react';
import * as Styled from './Notifications.styled';
import Indicator from '../../atoms/Indicator/Indicator';
import useOutsideClick from '../../../hooks/useOutsideClick';
import api from '../../../services/api';
import { AxiosResponse } from 'axios';
import { NotificationContentType, NotificationType } from '../../../@types/Notification/NotificationType';
import Notification from '../../molecules/Notification/Notification';
import Heading from '../../atoms/Heading';
import Text from '../../atoms/Text';
import { Icon } from '../../atoms/Icon/Icon';
import CompleteIcon from '../../../Icons/Complete.icon';
import { color } from '../../../styles/Variables';
import Form from '../../molecules/Form/Form';
import NotificationTypeSelect from './NotificationTypeSelect/NotificationTypeSelect';
import serverEvents from '../../../services/serverEvents';
import useUser from '../../../hooks/useUser';
import IriHelper from '../../../helpers/iri-helper';

type Props = {};

type ResponseType = AxiosResponse<{
  'hydra:member': NotificationType[];
}>;

const Notifications: FunctionComponent<Props> = (props) => {
  const [show, setShow] = React.useState(false);
  const user = useUser();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [notifications, setNotifications] = React.useState<NotificationType[]>([]);
  const [notificationType, setNotificationType] = useState<NotificationContentType>();
  const [lastChecked, setLastChecked] = useState<Date | null>(null);

  const loadNotifications = React.useCallback(() => {
    if (lastChecked && lastChecked > new Date(new Date().getTime() - 1000 * 60)) {
      return;
    }
    setLastChecked(new Date());
    api
      .get('/notifications', {
        params: {
          notificationType: notificationType,
        },
      })
      .then((r: ResponseType) => {
        const notifications = r.data['hydra:member'];
        setNotifications(notifications);
      });
  }, [notificationType, lastChecked]);

  const toggleShow = React.useCallback(() => {
    setShow((prevState) => !prevState);
  }, [show]);

  const closeNotifications = React.useCallback(() => {
    setShow(false);
  }, []);

  useOutsideClick(wrapperRef, closeNotifications);

  const markAllAsRead = React.useCallback(() => {
    api.post('/notifications/mark-read').then(() => {
      setNotifications((notifications) => {
        return notifications.map((n) => ({
          ...n,
          isRead: true,
        }));
      });
    });
  }, []);

  const counter = useMemo(() => {
    return notifications.reduce((previousValue, notification) => {
      return notification.isRead ? previousValue : previousValue + 1;
    }, 0);
  }, [notifications, loadNotifications]);

  const markAsRead = React.useCallback((notification: NotificationType, close = true, toggle = false) => {
    api
      .put(notification['@id'].substr(4), {
        isRead: toggle ? !notification.isRead : true,
      })
      .then((r: AxiosResponse<NotificationType>) => {});
    setNotifications((notifications) => {
      return notifications.map((n) => ({
        ...n,
        isRead: notification['@id'] === n['@id'] ? (toggle ? !n.isRead : true) : n.isRead,
      }));
    });
    if (close) {
      setShow(false);
    }
  }, []);

  useEffect(() => {
    const subscription = serverEvents.listen<NotificationType>('/api/notifications').subscribe((event) => {
      if (+IriHelper.iriToId(event.recipient['@id'])! == user.employeeId) {
        setNotifications((p) => [event, ...p.filter((e) => e['@id'] !== event['@id'])]);
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [user]);

  useEffect(() => {
    loadNotifications();
  }, []);
  return (
    <Styled.IndicatorWrapper ref={wrapperRef}>
      {counter > 0 && <Styled.Counter>{counter}</Styled.Counter>}
      <Indicator onClick={toggleShow} />
      <Styled.Wrapper $isVisible={show}>
        <Styled.HeaderContainer>
          <Heading level={3} size={'m'}>
            Notifications
          </Heading>
          <Styled.AllAsReadWrapper onClick={markAllAsRead}>
            <Icon color={color.primary['60']} size={1.2}>
              <CompleteIcon />
            </Icon>
            <Text size={'m'} color={'grey'} bold>
              Mark all as read
            </Text>
          </Styled.AllAsReadWrapper>
        </Styled.HeaderContainer>
        <div style={{ padding: '0 3.2rem 1.2rem 2.2rem' }}>
          <Form onSubmit={() => {}}>
            <NotificationTypeSelect onSelect={setNotificationType} />
          </Form>
        </div>
        {notifications.map((notification) => (
          <Notification key={notification['@id']} onRead={markAsRead} notification={notification} />
        ))}
      </Styled.Wrapper>
    </Styled.IndicatorWrapper>
  );
};

export default Notifications;
