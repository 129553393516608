import React, { FunctionComponent } from 'react';
import Avatar from '../../../atoms/Avatar/Avatar';
import { BasicEmployeeType } from '../../../../@types/Employee/EmployeeType';

type Props = {
  avatarSize: number;
  move: number;
  idx: number;
  employee: BasicEmployeeType;
};

const AttendeeEmployee: FunctionComponent<Props> = ({ avatarSize, move, idx, employee }) => {
  return (
    <div style={{ marginLeft: `${(avatarSize / move) * idx}rem`, position: 'absolute', padding: 0 }}>
      <Avatar size={avatarSize} employee={employee} border={true} tooltipPosition={'bottomLeft'} style={{ padding: 0 }} />
    </div>
  );
};

export default AttendeeEmployee;
