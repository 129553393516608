import styled, { css } from 'styled-components';
import { color, fontSize, fontWeight } from '../../../styles/Variables';
import { ButtonKind } from './Button';

export type ButtonType = {
  $kind?: ButtonKind;
  $small?: boolean;
  $medium?: boolean;
  $full?: boolean;
  $disabled?: boolean;
  $hasChildren?: boolean;
  $iconColor?: string;
  $waiting?: boolean;
};

export const Wrapper = styled.div`
  display: flex;
  position: relative;
`;

export const Button = styled.button<ButtonType>`
  position: relative;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: center;
  color: ${color.light};
  border-radius: 0.6rem;
  border-bottom-right-radius: ${(props: ButtonType) => (props.$hasChildren ? '0' : '0.6rem')};
  border-top-right-radius: ${(props: ButtonType) => (props.$hasChildren ? '0' : '0.6rem')};
  width: ${(props) => (props.$full ? '100%' : 'max-content')};
  line-height: normal;
  outline: none;
  font-weight: ${fontWeight.bold};
  font-size: ${fontSize.body.m};
  letter-spacing: -0.26px;
  border: none;
  cursor: pointer;
  transition: all 0.15s ease-in-out;
  padding: ${(props) => (props.$small ? '0 10px' : props.$medium ? '0 12px' : '0 16px')};
  min-height: ${(props) => (props.$small ? '32px' : props.$medium ? '36px' : '40px')};
  &:active {
    outline: none;
  }
  ${(props) => buttonDynamicStyles(props)}
`;

export const Text = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export function buttonDynamicStyles(props: ButtonType) {
  return [
    props.$kind === 'primary' &&
      css`
        background-color: ${color.primary[60]};
        &:hover {
          background-color: ${color.primary[80]};
        }
        &:active {
          background-color: ${color.primary[90]};
        }
      `,
    props.$kind === 'secondary' &&
      css`
        background-color: ${color.neutral[80]};
        &:hover {
          background-color: ${color.neutral['70']};
        }
        &:active {
          background-color: ${color.neutral['60']};
        }
      `,
    props.$kind === 'ghost' &&
      css`
        background-color: transparent;
        color: ${color.neutral[60]};
        border: 1px solid ${color.neutral[50]};
        div {
          color: ${color.primary[60]};
        }
        &:hover {
          color: ${color.primary[70]};
          div {
            color: ${color.primary[70]};
          }
        }
        &:active {
          border-color: ${color.primary[90]};
          color: ${color.primary[90]};
          div {
            color: ${color.primary[90]};
          }
        }
      `,
    props.$kind === 'ghost-white' &&
      css`
        background-color: transparent;
        color: ${color.light};
        border: 1px solid ${color.light};
        &:hover {
          color: ${color.primary[60]};
          background-color: ${color.light};
        }
        &:active {
          border-color: ${color.primary[90]};
          color: ${color.primary[90]};
          div {
            color: ${color.primary[90]};
          }
        }
      `,
    props.$kind === 'link' &&
      css`
        background-color: transparent;
        color: ${color.neutral[60]};
        div {
          color: ${color.primary[60]};
        }
        &:hover {
          color: ${color.primary[70]};
          div {
            color: ${color.primary[70]};
            svg path {
              fill: ${color.primary[70]};
            }
          }
        }
        &:active {
          color: ${color.primary[90]};
          div {
            color: ${color.primary[90]};
          }
        }
      `,
    props.$kind === 'link' &&
      props.$iconColor &&
      css`
        div {
          color: ${props.$iconColor};
        }
      `,
    props.$kind === 'positive' &&
      css`
        background-color: ${color.secondary[60]};
        &:hover {
          background-color: ${color.secondary[80]};
        }
        &:active {
          background-color: ${color.neutral[80]};
        }
      `,
    props.$kind === 'negative' &&
      css`
        background-color: ${color.semantic.error[80]};
        &:hover {
          background-color: ${color.semantic.error[100]};
        }
        &:active {
          background-color: ${color.neutral[80]};
        }
      `,
    props.$disabled &&
      css`
        pointer-events: none;
        background-color: ${color.neutral[40]};
        color: ${color.neutral[60]};
        div {
          color: ${color.neutral[60]};
        }
      `,
    props.$disabled &&
      props.$kind === 'ghost' &&
      css`
        pointer-events: none;
        border-color: ${color.neutral[50]};
        background-color: transparent;
        color: ${color.neutral[50]};
      `,
    props.$disabled &&
      props.$kind === 'link' &&
      css`
        pointer-events: none;
        background-color: transparent;
        color: ${color.neutral[50]};
      `,
    props.$hasChildren &&
      css`
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      `,
  ];
}
