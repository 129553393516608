import React, { FunctionComponent, useMemo } from 'react';
import * as Styled from './Attendees.styled';
import { MeetingAttendeeType } from '../../../@types/Meeting/MeetingAttendeeType';
import AttendeeEmployee from './components/AttendeeEmployee';
import OutsideAttendee from './components/OutsideAttendee';
import ShowMore from './components/ShowMore';

type Props = {
  attendees: MeetingAttendeeType[];
};

const Attendees: FunctionComponent<Props> = ({ attendees }) => {
  const avatarSize = 3;
  const move = 1.2;

  const filteredAttendees = useMemo(() => {
    return attendees.filter((e) => e.name.includes('@') || e.employee).map((e) => e);
  }, [attendees]);

  return (
    <Styled.Attendees $width={(avatarSize / move) * filteredAttendees.length + move} $height={avatarSize}>
      {attendees.length > 6
        ? attendees
            .slice(0, 6)
            .map((person, idx) => (
              <>
                {idx !== 5 ? (
                  person.employee ? (
                    <AttendeeEmployee employee={person.employee} avatarSize={avatarSize} move={move} idx={idx} />
                  ) : (
                    <OutsideAttendee avatarSize={avatarSize} move={move} idx={idx} name={person.name} />
                  )
                ) : (
                  <ShowMore avatarSize={avatarSize} move={move} idx={idx} attendees={attendees} />
                )}
              </>
            ))
        : attendees.map((person, idx) => (
            <>
              {person.employee ? (
                <AttendeeEmployee avatarSize={avatarSize} move={move} idx={idx} employee={person.employee} />
              ) : (
                <OutsideAttendee avatarSize={avatarSize} move={move} idx={idx} name={person.name} />
              )}
            </>
          ))}
    </Styled.Attendees>
  );
};

export default Attendees;
