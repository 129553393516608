import styled, { css } from 'styled-components';
import { color, fontWeight } from 'styles/Variables';

type Props = {
  $photoUrl?: string;
  $size?: number;
  $border?: boolean;
};

export const TooltipWrapper = styled.div`
  display: flex;
  width: max-content;
`;

export const Avatar = styled.div<Props>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  width: 2rem;
  height: 2rem;
  font-size: ${(p) => (p.$size ?? 2) * 0.4}rem;
  font-weight: ${fontWeight.bold};
  color: ${color.light};
  background-color: ${color.primary[60]};
  position: relative;

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  ${(props: Props) =>
    props.$size &&
    css`
      width: ${props.$size}rem;
      height: ${props.$size}rem;
    `}

  ${(props: Props) =>
    props.$border &&
    css`
      border: 2px solid ${color.light};
    `}
`;
