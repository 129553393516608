import React, { FunctionComponent, useCallback } from 'react';
import { color } from '../../../../../../styles/Variables';
import DashboardCard from '../DashboardCard';
import * as Styled from '../../../../../molecules/Projects/Dashboard/DashboardCardContentCards/ContentCard.styled';
import InOfficeCardItem from '../../../../../molecules/Projects/Dashboard/DashboardCardItems/InOfficeCardItem';
import Text from '../../../../../atoms/Text';
import Form from '../../../../../molecules/Form/Form';
import DatePicker from '../../../../../molecules/Form/DatePicker/DatePicker';
import { PiBuildingOffice } from 'react-icons/pi';
import api from '../../../../../../services/api';
import { ArrayResponseType } from '../../../../../../@types/hydra/hydra';

type Props = {
  inOfficeToday: { id: number; name: string }[];
};

const InOffice: FunctionComponent<Props> = ({ inOfficeToday }) => {
  const [entries, setEntries] = React.useState(inOfficeToday);

  const changeDate = useCallback((date: null | string) => {
    if (!date) {
      setEntries(inOfficeToday);
      return;
    }
    api
      .get<ArrayResponseType<{ id: number; name: string }>>('/employees-in-office', {
        params: {
          day: date,
        },
      })
      .then((response) => {
        setEntries(response.data['hydra:member']);
      });
  }, []);

  return (
    <DashboardCard title={'In office'} icon={<PiBuildingOffice color={color.primary['60']} />}>
      <Styled.ItemsColumnWrapper $scrollable={true} $heightLocked>
        <Form onSubmit={() => {}}>
          <DatePicker
            name={'date'}
            label={'Day'}
            onChange={(value) => {
              changeDate(value);
            }}
            placeholder={'Select day'}
          />
        </Form>
        <Text size={'xs'} color={'grey'} bold>
          Total: <span style={{ color: color.primary['70'] }}>{entries.length}</span>
        </Text>
        <>
          {entries.map((inOffice, index) => (
            <>
              <InOfficeCardItem person={inOffice} key={index} />
            </>
          ))}
        </>
      </Styled.ItemsColumnWrapper>
    </DashboardCard>
  );
};

export default InOffice;
