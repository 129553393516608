import React, { FunctionComponent } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import api from '../../../../services/api';
import { ArrayResponseType } from '../../../../@types/hydra/hydra';
import { AbsenceCalendarType } from '../../../../@types/Absences/AbsenceCalendarType';
import { DictValue } from '../../../../@types/Dictionary/DictValue';
import { color } from '../../../../styles/Variables';
import { HolidayType } from '../../../../@types/Holiday/HolidayType';
import styled from 'styled-components';

type Props = {};

const absenceTypeDict: DictValue[] = [
  { value: 'vacation', label: 'Vacation' },
  { value: 'home_office', label: 'Home office' },
  { value: 'sick_leave', label: 'Sick leave' },
];

const translateType = (value: string): string => {
  return absenceTypeDict.find((a) => a.value === value)?.label || value;
};

const colorByType = (type: string): string => {
  switch (type) {
    case 'vacation':
      return color.primary['60'];
    case 'home_office':
      return color.additionals.purple;
    case 'sick_leave':
      return color.neutral['50'];
    default:
      return 'black';
  }
};

const CalendarView: FunctionComponent<Props> = (props) => {
  return (
    <StyledCalendarView>
      <FullCalendar
        businessHours={{
          daysOfWeek: [1, 2, 3, 4, 5],
        }}
        firstDay={1}
        plugins={[dayGridPlugin]}
        initialView="dayGridMonth"
        events={async (t: any) => {
          const promises = [
            api
              .get<ArrayResponseType<AbsenceCalendarType>>('/absences-by-month', {
                params: {
                  dateFrom: t.startStr,
                  dateTo: t.endStr,
                },
              })
              .then((r) =>
                r.data['hydra:member'].map((a) => ({
                  title: `${a.person.name} (${translateType(a.type)})`,
                  start: a.dateFrom,
                  end: a.dateTo,
                  color: colorByType(a.type),
                })),
              ),
            api
              .get<ArrayResponseType<HolidayType>>('/holidays', {
                params: {
                  'date[after]': t.startStr,
                  'date[before]': t.endStr,
                },
              })
              .then((r) =>
                r.data['hydra:member'].map((a) => ({
                  title: a.name,
                  start: a.date,
                  allDay: true,
                  eventColor: 'red',
                  display: 'background',
                  color: color.primary['50'],
                })),
              ),
          ];
          return Promise.all(promises).then((r) => r.flat());
        }}
      />
    </StyledCalendarView>
  );
};

const StyledCalendarView = styled.div`
  .fc-bg-event > .fc-event-title {
    color: ${color.white};
  }
`;

export default CalendarView;
