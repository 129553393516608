import React, { FunctionComponent } from 'react';
import * as Styled from './WallEntryAuthor.styled';
import { BasicEmployeeType } from '../../../../@types/Employee/EmployeeType';
import Avatar from '../../../atoms/Avatar/Avatar';
import Text from '../../../atoms/Text/Text';
import { parseDate } from '../../../../helpers/date';
import { fontWeight } from '../../../../styles/Variables';

type Props = {
  person: BasicEmployeeType;
  date: Date | null;
};

const FeedEntryAuthor: FunctionComponent<Props> = ({ person, date }) => {
  return (
    <Styled.WallEntryAuthorStyled>
      <Avatar employee={person} size={4.2} />
      <Styled.InfoWrapper>
        <Text size={'xl'} bold>
          {person.name}
        </Text>
        <Text color={'semiGrey'} style={{ fontWeight: fontWeight.medium }} size={'s'}>
          {date ? parseDate(date, false, false, false, false, false, true) : ''}
        </Text>
      </Styled.InfoWrapper>
    </Styled.WallEntryAuthorStyled>
  );
};

export default FeedEntryAuthor;
