import React, { FunctionComponent } from 'react';
import Form from '../../../components/molecules/Form/Form';
import { TextInput } from '../../../components/molecules/Form/TextInput/TextInput';
import Button from '../../../components/atoms/Button/Button';
import * as Styled from '../LoginScreen.styled';
import { AuthContext } from '../../../Context/auth-context/auth-context';
import Text from '../../../components/atoms/Text';
import type { Location } from '@remix-run/router';
import { PiPaperPlaneTiltBold } from 'react-icons/pi';

type Props = {
  from: Partial<Location>;
};

const LoginForm: FunctionComponent<Props> = ({ from }) => {
  const { authenticate, state } = React.useContext(AuthContext);
  const [status, setStatus] = React.useState<'idle' | 'working' | 'sent'>('idle');
  const [lastEmail, setLastEmail] = React.useState<string | null>(localStorage.getItem('lastEmail'));

  const onSubmit = (data: { username: string }) => {
    setStatus('working');
    setLastEmail(data.username);
    localStorage.setItem('lastEmail', data.username);
    authenticate(data.username, from).then(() => {
      setStatus('sent');
    });
  };

  const buttonText = status === 'idle' ? 'Send me link' : status === 'working' ? 'Please wait...' : 'Check your email';

  return (
    <Form onSubmit={onSubmit} defaultValues={{ username: lastEmail }}>
      <Styled.HeaderWrapper>
        <Styled.Header $size={'l'}>Sign in</Styled.Header>
        <Text size={'l'} color={'grey'}>
          Please enter your email or username
        </Text>
      </Styled.HeaderWrapper>
      <Styled.InputWrapper>
        <div>
          <TextInput name={'username'} label={'Username'} placeholder={'Type your username or mail'} required />
        </div>
      </Styled.InputWrapper>
      <Styled.ButtonsWrapper>
        <Button $text={buttonText} full={true} type={'submit'} $icon={<PiPaperPlaneTiltBold />} disabled={status !== 'idle'} />

        {state.state === 'INVALID_CREDENTIALS' && <Text color={'error'}>Invalid credentials</Text>}
      </Styled.ButtonsWrapper>
    </Form>
  );
};

export default LoginForm;
