import { BodyText } from '../../../../styles/Typography';
import { color } from '../../../../styles/Variables';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Welcome = styled.div<{ $isImpersonating: boolean }>`
  ${BodyText};

  ${(props) =>
    props.$isImpersonating &&
    css`
      color: ${color.additionals.purple};
    `}

  span {
    font-weight: bold;
  }
`;
