import React, { FunctionComponent } from 'react';
import * as Styled from './LoginScreen.styled';
import loginPhoto from '../../assets/images/login/backgrounds/loginphoto.jpg';
import { useLocation } from 'react-router-dom';
import LoginForm from './Forms/LoginForm';
import { Logo } from '../../components/atoms/Logo/Logo';
import type { Location } from '@remix-run/router';

type Props = {};

const LoginScreen: FunctionComponent<Props> = () => {
  const location: { state: { from: Location } } = useLocation();
  const from: Partial<Location> = location.state?.from
    ? location.state.from
    : {
        pathname: '/',
        search: '',
      };

  return (
    <Styled.LoginScreen>
      <Styled.LeftGridItem $desktop={5}>
        <Styled.FormContainer>
          <Styled.LogoWrapper>
            <Logo width={80} height={34} />
          </Styled.LogoWrapper>
          <LoginForm from={from} />
        </Styled.FormContainer>
      </Styled.LeftGridItem>
      <Styled.RightGridItem $desktop={7}>
        <Styled.LoginPhotoWrapper>
          <Styled.LoginPhoto $background={loginPhoto}></Styled.LoginPhoto>
        </Styled.LoginPhotoWrapper>
      </Styled.RightGridItem>
    </Styled.LoginScreen>
  );
};

export default LoginScreen;
