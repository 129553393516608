import { CellTemplate } from '../../CellTemplate';
import React, { useMemo } from 'react';
import Avatar from '../../../../atoms/Avatar/Avatar';
import Text from '../../../../atoms/Text';
import { BasicEmployeeType } from '../../../../../@types/Employee/EmployeeType';
import { Grid, GridItem } from '../../../../atoms/Grid/Grid';
import { TextSizeType } from '../../../../../@types/Text/TextSizeType';
import { ColorType } from '../../../../../@types/Color/ColorType';
import EmptyCell from '../EmptyCell';

const PersonTemplate: CellTemplate<
  BasicEmployeeType,
  { colored?: boolean; areInitials?: boolean; textProperties?: { shorten?: number; bold?: boolean; size?: TextSizeType; color?: ColorType } },
  any
> = ({ item, value, options = {} }) => {
  item = item['@type'] === 'Employee' ? item : value;
  const initials = useMemo(() => {
    if (!item || !item.name) {
      return (
        <Text color={'lightGrey'} size={'s'} bold>
          N/A
        </Text>
      );
    }
    const [lastName, firstName] = item.name.split(' ');
    return `${firstName[0]}${lastName[0]}`;
  }, [item]);

  return (
    <Grid spacing={0.6} $align={'center'} style={{ flexWrap: 'nowrap' }} title={item?.name}>
      {item ? (
        <>
          <GridItem>
            <Avatar employee={item} />
          </GridItem>
          <GridItem>
            <Text
              color={options?.textProperties?.color ?? 'darkGrey'}
              size={options?.textProperties?.size ?? 'l'}
              bold={options?.textProperties?.bold ?? false}
            >
              {options.areInitials ? initials : item.name}
            </Text>
          </GridItem>
        </>
      ) : (
        <EmptyCell />
      )}
    </Grid>
  );
};
export default PersonTemplate;
