import React, { FunctionComponent, useMemo } from 'react';
import * as Styled from './Items.styled';
import Avatar from '../../../../atoms/Avatar/Avatar';
import Text from '../../../../atoms/Text';
import { parseDate } from '../../../../../helpers/date';
import { DashboardDataAbsenceType } from '../../../../../@types/Dashboard/DashboardDataAbsenceType';
import { fontWeight } from '../../../../../styles/Variables';
import { BasicEmployeeType } from '../../../../../@types/Employee/EmployeeType';

type Props = {
  person: { name: string; id: number };
};

const InOfficeCardItem: FunctionComponent<Props> = ({ person }) => {
  return (
    <Styled.CardItemWrapper $cursor={'default'}>
      <Avatar size={3} employee={person as BasicEmployeeType} />
      <Styled.NameWrapper>
        <Text size={'l'} bold>
          {person.name}
        </Text>
      </Styled.NameWrapper>
    </Styled.CardItemWrapper>
  );
};

export default InOfficeCardItem;
