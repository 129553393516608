import React, { FunctionComponent, useCallback } from 'react';
import { TaskListType } from '../../../../../@types/Task/TaskType';
import * as Styled from './Items.styled';
import { Icon } from '../../../../atoms/Icon/Icon';
import { color } from '../../../../../styles/Variables';
import Text from '../../../../atoms/Text/Text';
import Avatar from '../../../../atoms/Avatar/Avatar';
import CalendarInCircleIcon from '../../../../../Icons/CalendarInCircle.icon';
import useUser from '../../../../../hooks/useUser';
import AvatarInCircleIcon from '../../../../../Icons/AvatarInCircle.icon';
import useTaskSidebar from '../../../../../hooks/useTaskSidebar';
import TaskStatus from '../../../Statuses/TaskStatus/TaskStatus';
import TaskEmployeeDropdown from '../../../Task/TaskForm/TaskEmployeeDropdown/TaskEmployeeDropdown';
import EditableDetail from '../../../EditableDetail/EditableDetail';
import TaskDeadlineDropdown from '../../../Task/TaskForm/TaskDeadlineDropdown/TaskDeadlineDropdown';
import { shorten } from '../../../../../helpers/shorten';
import Tooltip from '../../../Tooltip/Tooltip';
import PriorityTag from '../../../PriorityTag/PriorityTag';
import AttachmentIcon from '../../../../../Icons/Attachment.icon';
import TaskDeadlineDate from '../../../TaskDeadlineDate/TaskDeadlineDate';
import BetterChip from '../../../../atoms/Chips/BetterChip';
import { IoMdPerson } from 'react-icons/io';

type Props = {
  task: TaskListType;
  onUpdate?: (task: TaskListType) => void;
};

const TasksCardItem: FunctionComponent<Props> = ({ task, onUpdate }) => {
  const user = useUser();
  const { openTask } = useTaskSidebar();

  const onTaskClick = useCallback(() => {
    openTask(task['@id']);
  }, [task]);
  return (
    <Styled.CardItemWrapper
      onClick={(e) => {
        onTaskClick();
      }}
      $hover={true}
    >
      <Styled.ItemWrapper>
        <Styled.ItemLeftWrapper>
          <TaskStatus status={task.status} />
          <Text
            color={'darkGrey'}
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {task.title}
          </Text>
          {task.hasResources && (
            <Icon size={0.8} color={color.neutral[60]}>
              <AttachmentIcon />
            </Icon>
          )}
          {task.createdBy.id === user.employeeId && task.assignee.id === user.employeeId && (
            <Tooltip
              content={
                <Text size={'xs'} color={'light'}>
                  Personal&nbsp;task
                </Text>
              }
              trigger={<IoMdPerson color={color.neutral['60']} />}
              arrowPosition={'leftCenter'}
            />
          )}
          {(task.priority === 'High' || task.priority === 'ASAP') && <PriorityTag priority={task.priority} />}
          <div />
        </Styled.ItemLeftWrapper>
        <Styled.ItemRightWrapper>
          {task.project && (
            <BetterChip customBackgroundColor={task.project.client.color} $rounded>
              #{`${task.project.id}`} {shorten(task.project.name, 30, 30)}
            </BetterChip>
          )}
          {task.planned && <BetterChip $rounded>Planned</BetterChip>}
          <EditableDetail<TaskListType>
            name={'deadline'}
            dropdownPosition={'right'}
            subject={task}
            onUpdate={onUpdate}
            show={
              <>
                {task.deadline ? (
                  <TaskDeadlineDate task={task} />
                ) : (
                  <Styled.TaskItemAction>
                    <Icon size={1.5} color={color.neutral[60]} onClick={() => {}}>
                      <CalendarInCircleIcon />
                    </Icon>
                  </Styled.TaskItemAction>
                )}
              </>
            }
            component={TaskDeadlineDropdown}
          />
          <EditableDetail<TaskListType>
            name={'assignee'}
            subject={task}
            onUpdate={onUpdate}
            dropdownPosition={'right'}
            show={
              <>
                {task.assignee && task.assignee.id !== user.employeeId ? (
                  <div style={{ marginRight: '1.6rem' }}>
                    <Avatar employee={task.assignee} size={2.4} tooltipPosition={'rightCenter'} />
                  </div>
                ) : (
                  <Styled.TaskItemAction>
                    <Icon size={1.5} onClick={() => {}}>
                      <AvatarInCircleIcon color={color.neutral['60']} />
                    </Icon>
                  </Styled.TaskItemAction>
                )}
              </>
            }
            component={TaskEmployeeDropdown}
          />
        </Styled.ItemRightWrapper>
      </Styled.ItemWrapper>
    </Styled.CardItemWrapper>
  );
};

export default TasksCardItem;
